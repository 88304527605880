<template>
  <div class="main">
    <!-- 搜索栏 -->
    <ListToolBar
      :disabled="disabledCreateBtn"
      @getList="getList"
      @onCreate="onCreate"
      @onDelete="onDelete" />
    <!-- 表格 -->
    <el-table
      ref="table"
      :data="tableInfo.data"
      :header-cell-style="{
        background: '#F2F2F2',
        color: '#525252',
        fontSize: '13px',
        height: '30px'
      }"
      stripe
      border
      v-loading="loading"
      @selection-change="handleSelectionChange"
      style="height: calc(100% - 90px);"
      height="100%"
      class="tables">
      <el-table-column type="selection" width="40px" fixed align="center" />
      <el-table-column :label="t('user.sales_name')" min-width="400px">
        <template #default="scope"> 
          <el-select
            v-if="scope.row.type === 'add'"
            v-model="scope.row.sales"
            :placeholder="t('user.sales_name')"
            filterable
            @change="onUserChange(scope.row, scope.$index)"
            value-key="value" >
            <el-option
              v-for="(sales, index) in salesList"
              :key="index"
              :label="sales.label"
              :value="sales" />
          </el-select>
          <span v-else class="line-2">{{ scope.row.user ? scope.row.user.name : '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="t('product.product_name')" width="400px">
        <template #default="scope">
          <el-select
            v-if="scope.row.type === 'add'"
            v-model="scope.row.product_id"
            :disabled="!!!scope.row.user_id"
            :placeholder="t('product.product_name')"
            filterable
            @change="onProductChange(scope.row, scope.$index)">
            <el-option
              v-for="(product, index) in productList"
              :key="index"
              :label="product.label"
              :value="product.value" />
          </el-select>
          <span v-else class="line-2">{{ scope.row.product ? scope.row.product.name : '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" :label="t('table.action')" width="110px">
        <template #default="scope">
          <el-button
            v-if="scope.row.type"
            type="primary"
            :disabled="saveBtn"
            :loading="loadingBtn"
            link
            @click="saveData(scope.row)">
            <span>{{ t('form.save') }}</span>
          </el-button>
          <el-button
            v-if="scope.row.type == 'add'"
            type="primary"
            link
            @click="deleteData(scope.$index)">
            <span>{{ t('form.delete') }}</span>
          </el-button>
          <el-popconfirm v-if="!scope.row.type" :title="t('form.sure_del')" @confirm="rowDel(scope.row)">
            <template #reference>
              <el-button link type="primary">
                <span>{{ t('form.delete') }}</span>
              </el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <Pager :totalPage="tableInfo.totalPage" :pageSize="tableInfo.pageSize" @getList="getList" />
  </div>
</template>

<script setup>
import ListToolBar from './components/ListToolBar'
import { onMounted, reactive, ref, nextTick, inject } from 'vue'
import { productManagement } from '@/utils/api'
import { getUserList, getUserProduct } from '@/utils/common'
import { ElMessage } from 'element-plus'

const t = inject('t')
const disabledCreateBtn = ref(false)
const salesList = ref([])// 企业列表
const productList = ref([])// 产品列表
const loading = ref(true)
const loadingBtn = ref(false) // 保存按钮loading
const saveBtn = ref(true)
const delList = ref([])//删除列表

// 检测销售下是否存在该产品
const checkExist = (data) => {
  return new Promise((resolve, reject) => {
    productManagement.SalesProductList({
      filter_product_id: data.product_id,
      filter_user_id: data.user_id
    }).then(res => {
      if (res.items.length === 0) {
        resolve(1)
      } else {
        ElMessage.warning(t('enterprise.exist_product'))
        resolve(0)
      }
    })
  })
}

// 销售改变时触发
const onUserChange = async (value) => {
  // 根据企业所属销售查询产品列表
  value.product_id = ''
  value.user_id = value.sales.value
  productList.value = await getUserProduct()
}

// 产品change时触发
const onProductChange = async (data) => {
  if (!!!data.user_id) {
    ElMessage.warning(t('product.select_sale'))
    return
  }
  if (!!!data.product_id) {
    ElMessage.warning(t('product.select_product'))
    return
  }
  const res = await checkExist(data)
  saveBtn.value = !!!res
}

// 创建
const onCreate = async () => {
  disabledCreateBtn.value = true
  salesList.value = await getUserList({ filter_role_alias: 'SalesMan' })
  tableInfo.data.unshift({
    user_id: "", //销售id
    sales: {}, //销售
    type: 'add',
    product_id: ''
  })
}

// 保存
const saveData = async (data) => {
  if (!!!data.user_id) {
    ElMessage.warning(t('product.select_sale'))
    return
  }
  if (!!!data.product_id) {
    ElMessage.warning(t('product.select_product'))
    return
  }
  const res = await checkExist(data)
  if (res) {
    await productManagement.SalesProductCreate(data)
    ElMessage.success(t('table.add_success'))
    getList()
  }
}

// 删除
const onDelete = async () => {
  if (!!!delList.value.length) {
    ElMessage.warning(t('table.select_del'))
    return
  }
  let arr = []
  delList.value.forEach(item => {
    arr.push(deleteRequest(item))
  })
  await nextTick()
  Promise.all(arr).then(() => {
    ElMessage.success(t('table.del_success'))
    getList()
    delList.value = []
  })
}

// 多选事件
const handleSelectionChange = (data) => {
  delList.value = [];
  data.forEach(item => {
    delList.value.push(item.id)
  })
}

// 删除:不请求接口
const deleteData = () => {
  disabledCreateBtn.value = false
  tableInfo.data.splice(0, 1)
}

// 行内点击删除
const rowDel = async (data) => {
  const res = await deleteRequest(data.id)
  if (res) {
    ElMessage.success(t('table.del_success'))
    getList()
  }
}
// 删除:请求接口
const deleteRequest = (id) => {
  return new Promise((resolve, reject) => {
    productManagement.SalesProductDelete(id).then(res => {
      resolve(1)
    })
  })
}

const tableInfo = reactive({
  data: [],
  totalPage: 0,
  pageSize: 0
})

// 获取表格数据
const getList = (search = {}) => {
  loading.value = true
  saveBtn.value = true;
  disabledCreateBtn.value = false;
  productManagement.SalesProductList({
    ...search
  }).then(data => {
    tableInfo.data = data.items;
    tableInfo.totalPage = data.total
    tableInfo.pageSize = data.per_page
    loading.value = false
  }).catch(error => {
    loading.value = false
  })
}

onMounted(() => {
  getList()
})
</script>


<style scoped lang="scss">
.tables {
  margin-top: 10px;
}

.el-button--small {
  padding: 0 2px !important;
}

.el-table .el-table__cell {
  box-sizing: border-box;
  min-height: 46px !important;
  padding: 6px 0 !important;
  font-size: 13px;
}

:deep(.el-table__body-wrapper) {
  overflow-y: scroll !important;
}

.el-table td.el-table__cell div {
  box-sizing: border-box !important;
  padding-right: 10px !important;
}
</style>